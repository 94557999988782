import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import DefaultPageStyle from 'assets/jss/material-kit-react/views/DefaultPage.jsx';
import image from 'assets/img/series-land-rover-restoration.jpg';
import withRoot from 'utils/withRoot.jsx';
import ContactSnippet from 'components/ContactSnippet/ContactSnippet.jsx';
import Info from 'components/Pages/Services/EngineTuning/Sections/Info.jsx';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import TripleGallery from 'components/TripleGallery/TripleGallery';

class EngineTuning extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 5,
            color: 'white',
          }}
          {...rest}
        />
        <Parallax small image={image}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.title}>Engine Tuning</h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <Info />
          <TripleGallery
            imageOne="defnder-90-td5-country-station-wagon-interior"
            imageTwo="land-rover-110-restored"
            imageThree="land-rover-110-v8-1994-restored"
          />
          <ContactSnippet />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRoot(withStyles(DefaultPageStyle)(EngineTuning));
