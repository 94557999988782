import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import DefaultPageStyle from 'assets/jss/material-kit-react/views/DefaultPage.jsx';
import image from 'assets/img/series-land-rover-restoration.jpg';
import Address from 'components/Address/Address.jsx';
import { Helmet } from 'react-helmet';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import withRoot from '../../../utils/withRoot';
import Message from './Sections/Message.jsx';

class ContactSent extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        </div>
        <div>
          <Header
            rightLinks={<HeaderLinks />}
            fixed
            color="transparent"
            changeColorOnScroll={{
              height: 5,
              color: 'white',
            }}
            {...rest}
          />
        </div>
        <div>
          <Parallax small image={image}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>Contact Us</h2>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <Message />
          <Address />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRoot(withStyles(DefaultPageStyle)(ContactSent));
