import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class Info extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <GridContainer spacing={16}>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <h3>
                  Launched in 1989, the Discovery helped bridge the gap between
                  the up-market Range Rover and dependable workhorse, the
                  Defender. Since then, it has become one of the most popular
                  Land Rovers of all time – especially here at LR-XS.
                </h3>
              </div>
            </GridItem>
          </GridContainer>

          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Discovery (Models 1 & 2)</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      <small>£</small>
                      <b>POA</b>
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h6>
                      Engine oil and filter change <br /> <br />
                      Waste disposal
                      <br /> <br />
                      Air filter replacement <br /> <br />
                      Fuel filter replacement <br /> <br />
                      Auxiliary fluid top-up <br /> <br />
                      External body and wheel hot wash
                      <br /> <br />
                      Vehicle health check report <br />
                    </h6>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Discovery (Models 3 & 4)</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      <small>£</small>
                      <b>POA</b>
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h6>
                      Engine oil and filter change <br /> <br />
                      Waste disposal
                      <br /> <br />
                      Air filter replacement <br /> <br />
                      Pollen filter replacement
                      <br /> <br />
                      Fuel filter replacement <br /> <br />
                      Auxiliary fluid top-up <br /> <br />
                      External body and wheel hot wash
                      <br /> <br />
                      Vehicle health check report <br />
                    </h6>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Info);
