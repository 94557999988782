import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class Info extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Diesel Engine Tuning</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      Starting at £<b>295</b> plus VAT
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h5>
                      Most modern Land Rover and Range Rover vehicles are
                      powered by turbo diesel engines, there is much potential
                      locked away that just needs the correct knowledge and
                      tools to extract. We work closely with Bell Auto Services
                      of York and have equipment that can upload specific
                      mapping designed by Pete to your Land Rover or Range Rover
                      vehicle. <br /> <br />
                      We have upgraded a surprising amount of vehicles that have
                      already been mapped by other companies, our customers
                      usually report that their vehicle can pull well at
                      Motorway speeds but general drivability is very poor
                      especially the later Defender 2.4 and 2.2 Ford derived
                      engines, they have a tendency to kangaroo at slower
                      speeds. Pete at Bell Auto Services focuses on getting the
                      pedal feel just right and ensuring the engine pulls well
                      from idle into the mid-range, not just at higher RPMS this
                      is important on Defenders as that’s exactly how they are
                      driven.
                    </h5>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Petrol Engine Tuning</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      Starting at £<b>395</b> plus VAT
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h5>
                      Land Rover and Range Rover petrol engines vary
                      substantially in their tuning and performance potential.{' '}
                      <br /> <br />
                      Starting with the basics we have the knowledge to properly
                      tune and setup your old Series 2.25 petrol or 2.5L petrol
                      90/110, electronic ignition upgrades are very popular and
                      the Turner Engineering gas flowed heads are also very
                      worthwhile enhancements. <br /> <br />
                      Essentially Rover V8s fitted in Defenders, Discovery’s and
                      Range Rovers can be tuned up and upgraded quite easily, we
                      have huge amounts of experience with the old General
                      Motors V8! It’s powered most of the models from the 1970
                      Range Rover right up to the very last 2004 Discovery 2
                      models, 34 years’ service is quite respectable! <br />{' '}
                      <br />
                      The more modern petrol engines are a little trickier to
                      enhance, your limited to tinkering with the superchargers
                      on Range Rovers, anything else involves major surgery!{' '}
                      <br /> <br />
                    </h5>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Info);
