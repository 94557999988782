import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import introStyle from 'assets/jss/material-kit-react/views/homePageSections/introStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import image from 'assets/img/land-rover-defender-90-offroad-wales.jpg';
import { ChatBubble } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';

class Intro extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <GridContainer spacing={16}>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <h2>Independent Land Rover Specialists based in Kent</h2>
              </div>
            </GridItem>
          </GridContainer>
          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={6}>
                <div>
                  <h5>
                    At LR-XS we are specialists in the Sales, Servicing and
                    Restoration of all Land Rover and Range Rover models.
                    {' '}
                    <br />
                    <br />
                    Be it yearly servicing or a large scale ground up
                    restoration we have the passion, knowledge and commitment to
                    ensure our customers are dealt with in a friendly yet
                    professional manner and are kept informed every step of the
                    way.
                    {' '}
                    <br />
                    {' '}
                    <br />
                    Land Rovers and Range Rovers are not only our business but
                    also our passion, it is this that drives us to deliver
                    exceptional service when taking care of our customers and
                    their vehicles.
                  </h5>
                  <Link to="/contact-us">
                    <Button
                      className={classes.container}
                      color="success"
                      size="lg"
                    >
                      <ChatBubble className={classes.icons} />
                      {' '}
Get in touch
                    </Button>
                  </Link>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <img
                  src={image}
                  alt="defender-90-offroad"
                  className={
                    `${classes.imgRaised
                    } ${
                      classes.imgRounded
                    } ${
                      classes.imgFluid}`
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(introStyle)(Intro);
