import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import DefaultPageStyle from 'assets/jss/material-kit-react/views/DefaultPage.jsx';
import image from 'assets/img/series-land-rover-restoration.jpg';
import withRoot from 'utils/withRoot.jsx';
import ContactSnippet from 'components/ContactSnippet/ContactSnippet.jsx';
import Info from 'components/Pages/Services/DiscoveryServicing/Sections/Info.jsx';
import { Helmet } from 'react-helmet';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import TripleGallery from 'components/TripleGallery/TripleGallery';

class DiscoveryServicing extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <title>Land Rover Discovery Servicing in Kent | Book today</title>
            <link
              rel="canonical"
              href="https://lr-xs.co.uk/services/land-rover-discovery-servicing"
            />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content="Land Rover Discovery Servicing in Kent | Book today"
            />
            <meta
              property="og:description"
              content="We offer servicing for all Land Rover and Range Rover models. Courtesy car included, get in touch today."
            />
            <meta
              property="og:url"
              content="https://lr-xs.co.uk/services/land-rover-discovery-servicing"
            />
            <meta property="og:site_name" content="LR-XS" />
          </Helmet>
        </div>
        <div>
          <Header
            rightLinks={<HeaderLinks />}
            fixed
            color="transparent"
            changeColorOnScroll={{
              height: 5,
              color: 'white',
            }}
            {...rest}
          />
        </div>
        <div>
          <Parallax small image={image}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>Discovery Servicing</h2>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <Info />
          <TripleGallery
            imageOne="defnder-90-td5-country-station-wagon-interior"
            imageTwo="land-rover-110-restored"
            imageThree="land-rover-110-v8-1994-restored"
          />
          <ContactSnippet />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRoot(withStyles(DefaultPageStyle)(DiscoveryServicing));
