import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { Link } from "gatsby";


class ContactForm extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="Intro">
            <GridContainer>
              <GridItem md={12} sm={12}>
                <div className={classes.container}>
                  <h2>
                    <b>
                      Following Government advice LR-XS remains active and are here to support with all essential services.       
                      </b>
                  </h2>
                  <h3>
                  At LR-XS we can offer help and support to all those in need whilst acting in accordance to Government Guidelines as set out on the 24th March 2020.
                  Running on reduced staff and operating on a strictly no contact basis, should you wish to make an appointment then please ensure you contact us first using the details below or via our             <Link to={"/contact-us"} className={classes.listItem}>
contact form.             </Link>

                  Stay safe, stay positive and we look forward to greeting you all when possible to do so.                        <br />

                       <br />

                  Our full policy will be available upon request outlining our current working procedures.
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(ContactForm);
