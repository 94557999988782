import React from 'react';
import { createMemoryHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

import 'assets/scss/material-kit-react.scss?v=1.4.0';
import 'typeface-roboto';
import 'typeface-roboto-slab';

import HomePage from '../components/Pages/HomePage/HomePage';
import AllServices from '../components/Pages/AllServices/AllServices.jsx';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import Covid from '../components/Pages/Covid/Covid';
import ContactSent from '../components/Pages/ContactSent/ContactSent';
import DefenderServicing from '../components/Pages/Services/DefenderServicing/DefenderServicing';
import EngineTuning from '../components/Pages/Services/EngineTuning/EngineTuning';
import GalvanisedChassis from '../components/Pages/Services/GalvanisedChassis/GalvanisedChassis';
import DiscoveryServicing from '../components/Pages/Services/DiscoveryServicing/DiscoveryServicing';
import RangeRoverServicing from '../components/Pages/Services/RangeRoverServicing/RangeRoverServicing';

const hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      <Route path="/" component={HomePage} />
      <Route path="/services" component={AllServices} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/message-sent" component={ContactSent} />
      <Route
        path="/services/land-rover-defender-servicing"
        component={DefenderServicing}
      />
      <Route
        path="/services/land-rover-engine-tuning"
        component={EngineTuning}
      />
      <Route
        path="/services/land-rover-galvanised-chassis-fitting"
        component={GalvanisedChassis}
      />
      <Route
        path="/services/land-rover-discovery-servicing"
        component={DiscoveryServicing}
      />
      <Route
        path="/services/range-rover-servicing"
        component={RangeRoverServicing}
      />
    </Switch>
  </Router>
);
