import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import galleryStyle from 'assets/jss/material-kit-react/views/homePageSections/galleryStyle.jsx';
import GridItem from 'components/Grid/GridItem.jsx';


function RenderGallery({ ...props }) {
  const { classes, imageList } = props;
  return Object.keys(imageList).map((key) => {
    const image = imageList[key][0];
    const imageAlt = imageList[key][1];
    return (
      <GridItem xs={12} sm={12} md={4}>
        <img
          src={require(`assets/img/${image}.jpg`)}
          alt={imageAlt}
          className={
              `${classes.imgRaised
              } ${
                classes.imgRounded
              } ${
                classes.imgFluid}`
            }
        />
      </GridItem>
    );
  });
}

export default withStyles(galleryStyle)(RenderGallery);
