import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class Info extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <GridContainer spacing={16}>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <h3>
                  The Range Rover remains a modern car of choice, particularly
                  as the more luxurious version of it’s cousins, the Defender
                  and the Discovery. Our servicing will ensure your Range Rover
                  continues to provide a deluxe driving experience.
                </h3>
              </div>
            </GridItem>
          </GridContainer>

          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Range Rover Classic & P38a models</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      <small>£</small>
                      <b>POA</b>
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h6>
                      Engine oil and filter change <br /> <br />
                      Waste disposal
                      <br /> <br />
                      Air filter replacement <br /> <br />
                      Fuel filter replacement <br /> <br />
                      Auxiliary fluid top-up <br /> <br />
                      External body and wheel hot wash
                      <br /> <br />
                      Vehicle health check report <br />
                    </h6>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes.centerContainer}>
                  <CardBody className={classes.centerContainer}>
                    <h2>Range Rover L322 (2002-2012 models)</h2>
                    <div className={classes.horizontalLine} />

                    <h1 className={classes.cardTitle}>
                      <small>£</small>
                      <b>POA</b>
                    </h1>
                    <div className={classes.horizontalLine} />

                    <h6>
                      Engine oil and filter change <br /> <br />
                      Waste disposal
                      <br /> <br />
                      Air filter replacement <br /> <br />
                      Fuel filter replacement <br /> <br />
                      Auxiliary fluid top-up <br /> <br />
                      External body and wheel hot wash
                      <br /> <br />
                      Vehicle health check report <br />
                    </h6>
                    <div className={classes.horizontalLine} />
                    <Link to="/contact-us">
                      <Button color="primary" size="lg">
                        Book Today
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Info);
