import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import homePageStyle from 'assets/jss/material-kit-react/views/homePage.jsx';
import Address from 'components/Address/Address.jsx';
import { Helmet } from 'react-helmet';
import RenderGallery from 'components/RenderGallery/RenderGallery.jsx';
import StockImageList from 'components/RenderGallery/StockImageList.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import withRoot from '../../../utils/withRoot';
import Intro from './Sections/Intro.jsx';

class HomePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <title>
              Independent Land Rover Specialist Kent | Sales, Servicing and
              Restoration
            </title>
            <meta
              name="description"
              content="LR-XS are specialists in the Sales, Servicing and Restoration of all Land Rover and Range Rover models, please get in contact with your requirements today."
            />
            <link rel="canonical" href="https://lr-xs.co.uk/" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Independent Land Rover Specialist Kent | Sales, Servicing and Restoration"
            />
            <meta
              property="og:description"
              content="LR-XS are specialists in the Sales, Servicing and Restoration of all Land Rover and Range Rover models, please get in contact with your requirements today."
            />
            <meta property="og:url" content="https://lr-xs.co.uk/" />
            <meta property="og:site_name" content="LR-XS" />
          </Helmet>
        </div>
        <div>
          <Header
            rightLinks={<HeaderLinks />}
            fixed
            color="transparent"
            changeColorOnScroll={{
              height: 200,
              color: 'white',
            }}
            {...rest}
          />
        </div>
        <div>
          <Parallax
            image={require('assets/img/defender-90-300tdi-restored.jpg')}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.centerContainer}>
                    <div className={classes.textCenter}>
                      <Link to="/contact-us" className={classes.listItem}>
                        <Button color="success" size="lg">
                          Book a free <br/> consultation today
                        </Button>
                      </Link>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Intro />
          <div className={classes.sections}>
            <div className={classes.container}>
              <div id="Gallery">
                <GridContainer spacing={16}>
                  <RenderGallery imageList={StockImageList} />
                </GridContainer>
              </div>
            </div>
          </div>
          <Address />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRoot(withStyles(homePageStyle)(HomePage));
